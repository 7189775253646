import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo1 from '../../../assets/img/logo.jpeg';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import * as Yup from 'yup';
// import Alert from '../../../components/bootstrap/Alert';

const validationSchema = Yup.object().shape({
  username: Yup.string().email().required('Usuario es requerido'),
  password: Yup.string().required('Contraseña es requerida'),
});

interface ILoginHeaderProps {
  isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className='text-center h1 fw-bold mt-5'>Create Account,</div>
        <div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
      </>
    );
  }
  return (
    <>
      <div className='text-center h1 fw-bold mt-3 mb-5'>Sign in to your account</div>
      {/* <div className='text-center h4 text-muted mb-5'>Sign in to continue!</div> */}
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { setUser } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();

  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
  const [hasErrors, setErrors] = useState<boolean>(false);
  const [noUser, setNoUser] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/'), [navigate]);
  const [post, setPost] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);

  interface ILogin {
    username: string;
    password: string;
  }

  interface ILoginResponse {
    status: boolean;
    message: string;
    access_token: string;
    data: any;
  }

  const handleSubmit = (values: ILogin) => {
    // if (setUser) setUser(values.username);
    axios
      .post<ILoginResponse>(`${process.env.REACT_APP_API_URL}/api/auth`, {
        username: values.username,
        password: values.password,
      })
      .then((response) => {
        setUserInfo(response.data);
        localStorage.setItem('access_token', response.data.access_token)
        setPost(jwt_decode(response.data.access_token));
        if (setUser) setUser(values.username);
      })
      .catch(function (error) {
        console.log(error);
        setNoUser(true);
        setErrors(false);
      });
  };

  useEffect(() => {
    if (post?.role !== undefined) {
      if (
        process.env.REACT_APP_ADMINROLE === post?.role ||
        process.env.REACT_APP_SUPERADMINROLE === post?.role
      ) {
        handleOnClick();
      } else {
        setErrors(true);
        setNoUser(false);
      }
    }
  }, [post, handleOnClick]);

  useEffect(() => {
    if (userInfo?.data._embedded.user !== undefined) {
      localStorage.setItem('user_info', JSON.stringify(userInfo?.data._embedded.user));
    }
  }, [userInfo]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { username: '', password: '' },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleContinue = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (
        !Object.keys(USERS).find((f) => USERS[f].username.toString() === formik.values.username)
      ) {
        formik.setFieldError('username', 'No such user found in the system.');
      } else {
        setSignInPassword(true);
      }
      setIsLoading(false);
    }, 1000);
  };

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? 'Sign Up' : 'Login'}
      className={classNames({ 'bg-light': !singUpStatus })}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-6 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center mt-5'>
                  <Link
                    to='/'
                    className={classNames('text-decoration-none  fw-bold display-2', {
                      'text-dark': !darkModeStatus,
                      'text-light': darkModeStatus,
                    })}>
                    <img src={Logo1} alt='logo' height={120} />
                  </Link>
                </div>
                {/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div> */}

                <LoginHeader isNewUser={singUpStatus} />

                {/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
                <form className='row g-4 align-items-center justify-content-center mb-4'>
                  {singUpStatus ? (
                    <>
                      {/* <div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div> */}
                    </>
                  ) : (
                    <>
                      <div className='col-lg-9'>
                        <FormGroup
                          id='username'
                          isFloating
                          label='Email'
                          className={classNames({
                            'd-none': signInPassword,
                          })}>
                          <Input
                            autoComplete='username'
                            name='username'
                            value={formik.values.username}
                            isTouched={formik.touched.username}
                            invalidFeedback={formik.errors.username}
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => {
                              formik.setErrors({});
                            }}
                          />
                        </FormGroup>
                        <FormGroup
                          id='password'
                          isFloating
                          label='Password'
                          className={classNames({
                            'd-none': signInPassword,
                          })}>
                          <Input
                            type='password'
                            name='password'
                            autoComplete='current-password'
                            value={formik.values.password}
                            isTouched={formik.touched.password}
                            invalidFeedback={formik.errors.password}
                            validFeedback='Looks good!'
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className='mt-4'
                          />
                        </FormGroup>
                        {/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.username}.
													</div>
												)} */}
                        {/* <FormGroup
                          id='password'
                          isFloating
                          label='Password'
                          className={classNames({
                            'd-none': !signInPassword,
                          })}>
                          <Input
                            type='password'
                            autoComplete='current-password'
                            value={formik.values.password}
                            isTouched={formik.touched.password}
                            invalidFeedback={formik.errors.password}
                            validFeedback='Looks good!'
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </FormGroup> */}
                      </div>
                      <div className='col-lg-9 my-5'>
                        {signInPassword ? (
                          <Button
                            color='dark'
                            className='w-100 py-3 fw-bold'
                            isDisable={!formik.values.username}
                            onClick={handleContinue}>
                            {isLoading && <Spinner isSmall inButton isGrow />}
                            Continue
                          </Button>
                        ) : (
                          <div>
                            {hasErrors ? (
                              <div>
                                <div
                                  className='invalid-feedback'
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                  }}>
                                  Incorrect Role.
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {noUser ? (
                              <div>
                                <div
                                  className='invalid-feedback'
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                  }}>
                                  Incorrect user or password.
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            <Button color='link' className='w-100 py-3 fw-bold mb-2'>
                              <Link to='/auth-pages/forgot-password'>Forgot Password?</Link>
                            </Button>
                            <Button
                              color='dark'
                              className='w-100 py-3 fw-bold'
                              onClick={formik.handleSubmit}>
                              Sign In
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/* BEGIN :: Social Login */}
                  {!signInPassword && (
                    <>
                      {/* <div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div> */}
                    </>
                  )}
                  {/* END :: Social Login */}
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
